<template>
  <a-config-provider :locale="locale">
    <div id="app" class="app">
	  <!-- <BusinessBtn v-if="showBusinessBtn"/> -->
      <router-view />
    </div>
  </a-config-provider>
</template>
<script setup>
// import BusinessBtn from "./components/common/businessBtn.vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { Modal } from 'ant-design-vue'
import { ref,onMounted } from 'vue'
import { getCompanyId} from '@/utils/uniCookie.js'
let showBusinessBtn = ref(true);

onMounted(() => {

// if(window.location.pathname.includes('workWeixin/install')){
// 	showBusinessBtn.value = false;
// }else{
// 	showBusinessBtn.value = true;
// }
let modal = null;
  document.querySelector('#app').addEventListener("mouseenter", function(){
	let companyId = getCompanyId();
	if(window.companyId && companyId && companyId!= window.companyId){
		modal && modal.destroy();  
		modal = Modal.info({
			title: '温馨提示',
			content: '您的登录企业已发生变化，请刷新页面',
			okText: '刷新',
			onOk() {
				localStorage.setItem('currentNav',0);
				window.location.href='/';
			}
		})

	}
});
})

const locale = zhCN
</script>
