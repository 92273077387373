<template>
	<svg class="svg-icon" aria-hidden="true">
		<use :xlink:href="symbolId" :fill="color" />
	</svg>
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
	name: 'SvgIcon',
	props: {
		prefix: {
			type: String,
			default: 'icon'
		},
		type: {
			type: String,
			required: true
		},
		color: {
			type: String,
			default: '#333'
		}
	},
	setup(props) {
		const symbolId = computed(() => `#${props.prefix}-${props.type}`)
		return { symbolId }
	}
})
</script>

<style scoped>
.svg-icon {
	width: 12px;
	height: 12px;
	vertical-align: revert;
}
</style>
